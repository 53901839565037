@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", "Inter", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
}

.calendly-popup-content {
  height: 102% !important;
  overflow: hidden !important;
}
