.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.side-header {
  background-color: #e26834;
}

.sidebar-icon {
  color: #706565;
  cursor: pointer;
}

.sidebar-icon:hover {
  background-color: #eff3ff;
}

.page-main-header {
  color: #333333;
}

.page-sub-header {
  color: #727272;
}

.developer-friendly-container {
  background-image: url("./images/developer_focus.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.MuiPickersLayout-root .MuiDialogActions-root {
  display: none;
}

.MuiPickersLayout-root .MuiPickersToolbar-root {
  display: none;
}

.MuiPickersLayout-root .MuiPickersLayout-contentWrapper {
  width: fit-content;
}

.MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root {
  height: 300px;
  width: 280px;
}

/* .MuiPickersLayout-root .MuiPickersLayout-contentWrapper .MuiDateCalendar-root .MuiPickersCalendarHeader-root{
  margin: 0;
  padding: 0;
} */
